import { Box, styled } from '@mui/material';

export const Block = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('laptop')]: {
    padding: '60px 0',
  },
}));

export const Wrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  margin: 'auto',
  paddingBottom: '30px',
});
