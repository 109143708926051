import React from 'react';
import { Button, Typography } from '@mui/material';
import { Block, Wrapper } from 'pages/404/404.styled';
import { useTranslation } from 'next-i18next';
import { ArrowIcon, NotFoundIcon } from 'components/icons';
import { useRouter } from 'next/router';
import { PATHS } from 'utils';
import { useLaptop } from 'hooks';
import { MetaLayout } from 'components';
import { GetStaticProps } from 'next';
import { getTranslations } from '../../utils/i18n-cache';

const Custom404 = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { isOverLaptop } = useLaptop();
  const goHome = () => {
    return router.push(PATHS.home);
  };

  return (
    <MetaLayout title={t`app.404.error`}>
      <Wrapper>
        <Block>
          <NotFoundIcon />
          <Typography variant={isOverLaptop ? 'big400' : 'mid550'} mb={'30px'}>
            {t('app.404.sorry')}!
          </Typography>
          <Button
            variant={'contained'}
            endIcon={<ArrowIcon />}
            onClick={goHome}
          >
            {t('app.404.back')}
          </Button>
        </Block>
      </Wrapper>
    </MetaLayout>
  );
};

export default Custom404;

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await getTranslations(locale ?? 'en')),
  },
});
